import { DAY_IN_MILLIS } from "./time";

/*====================Modifiers========================*/
export function cloneDate(d: Date): Date {
  return new Date(d.getTime());
}

export function midday(d: Date): Date {
  const clone = cloneDate(d);
  clone.setHours(12, 0, 0, 0);

  return clone;
}

export function addMilliseconds(d: Date, millis: number): Date {
  const clone = cloneDate(d);
  clone.setTime(d.getTime() + millis);

  return clone;
}

export const addDays = (d: Date, days: number): Date => {
  return addMilliseconds(d, days * DAY_IN_MILLIS);
};

/*====================Getters========================*/
export function getToday(): Date {
  return midday(new Date());
}

export function getYesterday(): Date {
  return addDays(getToday(), -1);
}

export function isStartOfDay(d: Date) {
  return d.getTime() === startOfDay(d).getTime();
}

export function startOfDay(d: Date) {
  const clone = cloneDate(d);
  clone.setHours(0, 0, 0, 0);

  return clone;
}

export function endOfDay(d: Date) {
  const clone = cloneDate(d);
  clone.setDate(clone.getDate() + 1);
  clone.setHours(0, 0, 0, -1);

  return clone;
}

/*===================Compare=====================*/
export function isDayBefore(d1: Date, d2: Date) {
  const day1 = cloneDate(d1).setHours(0, 0, 0, 0);
  const day2 = cloneDate(d2).setHours(0, 0, 0, 0);

  return day1 < day2;
}

export function isDayAfter(d1: Date, d2: Date) {
  const day1 = cloneDate(d1).setHours(0, 0, 0, 0);
  const day2 = cloneDate(d2).setHours(0, 0, 0, 0);

  return day1 > day2;
}

export function isPastDay(d: Date) {
  return isDayBefore(d, getToday());
}

export function isSameYear(d1: Date | null | undefined, d2: Date | null | undefined) {
  if (!d1 || !d2) return false;

  return d1.getFullYear() === d2.getFullYear();
}

export function isSameMonth(d1: Date | null | undefined, d2: Date | null | undefined) {
  if (!d1 || !d2) return false;

  return d1.getMonth() === d2.getMonth() && isSameYear(d1, d2);
}

export function isSameDay(d1: Date | null | undefined, d2: Date | null | undefined) {
  if (!d1 || !d2) return false;

  return d1.getDate() === d2.getDate() && isSameMonth(d1, d2);
}

export function getDateFormat(locale: string) {
  const fallback = ["D", "M", "Y"];

  try {
    const formatter = new Intl.DateTimeFormat(locale, {
      weekday: "long",
      year: "numeric",
      month: "numeric",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
    });
    const parts = formatter.formatToParts(new Date());
    const filteredParts = parts.filter(({ type }) => ["day", "month", "year"].includes(type));

    if (filteredParts.length !== 3) return fallback;

    return filteredParts.map(({ type }) => type.charAt(0).toUpperCase());
  } catch {
    return fallback;
  }
}

export function stringToDate(value: string, locale: string): Date | null {
  const separator = "[^\\d, ]+";
  const regexParts: { [key: string]: string } = {
    Y: "(\\d{4})",
    M: "(\\d{1,2})",
    D: "(\\d{1,2})",
  };
  const dateFormat = getDateFormat(locale);
  const dateRegex = new RegExp(dateFormat.map((p) => regexParts[p]).join(separator));

  const match = value.match(dateRegex);

  if (!match) return null;

  const year = parseInt(match[dateFormat.indexOf("Y") + 1], 10);
  const month = parseInt(match[dateFormat.indexOf("M") + 1], 10);
  const day = parseInt(match[dateFormat.indexOf("D") + 1], 10);

  if (month < 1 || month > 12) return null;
  if (day < 1 || day > 31) return null;

  return midday(new Date(year, month - 1, day));
}
