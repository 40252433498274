import { cn } from "@nephroflow/design-system/styling/utils";
import { useMemo } from "react";
import {
  CaptionDropdowns,
  CaptionProps,
  CustomComponents,
  DayContentProps,
  DayModifiers,
  DayPickerProps,
  Formatters,
  Matcher,
  ModifiersClassNames,
  useDayPicker,
  useNavigation,
} from "react-day-picker";

import { defineMessages, useIntl } from "~/intl";

import { IconButton } from "~/shared/components/button";
import { Calendar } from "~/shared/components/calendar";
import { ArrowLeftIcon, ArrowRightIcon, EventIcon } from "~/shared/components/icon";

import { getToday, isDayAfter, isDayBefore, isSameDay } from "~/utils/date";

import { useShortMonths, useShortWeekdays } from "./translations";

const currentYear = new Date().getFullYear();
const maxYearDiff = 128;
const fromMonth = new Date(currentYear - maxYearDiff, 0, 1, 0, 0);
const toMonth = new Date(currentYear + maxYearDiff, 11, 31, 23, 59);

export type DisabledModifiers = Record<"disablePast" | "disableFuture" | "disableToday" | "disableDate", Matcher>;

export function DatePicker(
  props: Omit<DayPickerProps, "selected"> & {
    selected?: Date[] | undefined;
    asRange?: boolean;
    disabledModifiers?: DisabledModifiers;
  },
) {
  const { formatMonthNumber } = useShortMonths();
  const { formatDayNumber } = useShortWeekdays();

  const { asRange, disabledModifiers, ...dayPickerProps } = props;

  const getDayName = (date: Date) => formatDayNumber(date.getDay());
  const getMonthName = (date: Date) => formatMonthNumber(date.getMonth());

  const components = useMemo<CustomComponents>(
    () => ({ ...dayPickerProps.components, ...customComponents }),
    [dayPickerProps.components],
  );

  const modifiers: DayModifiers = {
    ...dayPickerProps.modifiers,
    disabled: Object.values<Matcher>(disabledModifiers || {}),
  };

  if (asRange) {
    const [from, to] = dayPickerProps.selected || [null, null];
    if (from && to) {
      modifiers[CustomModifiers.RangeStart] = (date) => isSameDay(date, from);
      modifiers[CustomModifiers.RangeCenter] = (date) => isDayAfter(date, from) && isDayBefore(date, to);
      modifiers[CustomModifiers.RangeEnd] = (date) => isSameDay(date, to);
    }
  }

  const rangeClasses: Record<string, string> = {
    [CustomModifiers.RangeStart]: "bg-blue-10 rounded-s",
    [CustomModifiers.RangeCenter]: "bg-blue-10",
    [CustomModifiers.RangeEnd]: "bg-blue-10 rounded-e",
  };

  const modifiersClassNames: ModifiersClassNames = {
    ...dayPickerProps.modifiersClassNames,
    ...Object.fromEntries(
      Object.values(CustomModifiers).map((value) => [value, `rdp-day_${value} ${rangeClasses[value] as string}`]),
    ),
  };

  const formatters: Partial<Formatters> = {
    ...dayPickerProps.formatters,
    formatWeekdayName: getDayName,
    formatMonthCaption: getMonthName,
  };

  return (
    <Calendar
      mode="default"
      weekStartsOn={0}
      fromMonth={disabledModifiers?.disablePast ? getToday() : fromMonth}
      toMonth={disabledModifiers?.disableFuture ? getToday() : toMonth}
      captionLayout="dropdown-buttons"
      showOutsideDays
      {...(dayPickerProps as any)}
      components={components}
      className={dayPickerProps.className}
      modifiersClassNames={modifiersClassNames}
      modifiers={modifiers}
      formatters={formatters}
    />
  );
}

enum CustomModifiers {
  RangeStart = "range_start",
  RangeCenter = "range_center",
  RangeEnd = "range_end",
}

const customComponents: CustomComponents = {
  DayContent,
  Caption,
};

function DayContent(props: DayContentProps) {
  const day = props.date.getDate();
  const testId = !props.activeModifiers.outside ? `day-${day}` : null;

  return (
    <div
      className={cn("flex h-10 w-10 items-center justify-center rounded hover:rounded", {
        "hover:bg-gray-10": !props.activeModifiers.selected,
        "bg-blue-100": props.activeModifiers.selected,
        "rounded outline outline-gray-100": props.activeModifiers.today && !props.activeModifiers.selected,
        "text-gray-100":
          !props.activeModifiers.outside && !props.activeModifiers.selected && !props.activeModifiers.today,
        "text-gray-20": props.activeModifiers.disabled,
      })}
      data-testid={testId}
    >
      {day}
    </div>
  );
}

function Caption(props: CaptionProps) {
  const { classNames } = useDayPicker();

  return (
    <div className={classNames.caption}>
      <CaptionDropdowns {...props} />
      <CaptionNavigation />
    </div>
  );
}

function CaptionNavigation() {
  const { formatMessage } = useIntl();
  const { classNames } = useDayPicker();
  const { previousMonth, nextMonth, goToMonth } = useNavigation();

  if (!nextMonth && !previousMonth) return <></>;

  const handlePrevClick = () => {
    if (previousMonth) goToMonth(previousMonth);
  };

  const handleCurrClick = () => {
    goToMonth(new Date());
  };

  const handleNextClick = () => {
    if (nextMonth) goToMonth(nextMonth);
  };

  return (
    <div className={classNames.nav}>
      <IconButton
        importance="tertiary"
        type="button"
        impact="neutral"
        title={formatMessage(t.prevMonth)}
        icon={<ArrowLeftIcon className={cn("text-blue", { "text-gray-20": !previousMonth })} />}
        onClick={handlePrevClick}
        disabled={!previousMonth}
      />
      <IconButton
        type="button"
        importance="tertiary"
        impact="neutral"
        title={formatMessage(t.getToday)}
        icon={<EventIcon className="text-blue" />}
        onClick={handleCurrClick}
      />

      <IconButton
        type="button"
        importance="tertiary"
        impact="neutral"
        title={formatMessage(t.nextMonth)}
        icon={<ArrowRightIcon className={cn("text-blue", { "text-gray-20": !nextMonth })} />}
        onClick={handleNextClick}
        disabled={!nextMonth}
      />
    </div>
  );
}

const t = defineMessages({
  prevMonth: {
    id: "date_picker_previous_month",
    defaultMessage: "Previous month",
  },
  nextMonth: { id: "date_picker_next_month", defaultMessage: "Next month" },
  getToday: { id: "date_picker_today", defaultMessage: "Today" },
});
