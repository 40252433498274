import * as Popover from "@radix-ui/react-popover";
import React, { forwardRef, ReactNode } from "react";

import { useIntl } from "~/intl";

import { DropdownMenu } from "~/shared/components/dropdown-menu";
import { CloseIcon } from "~/shared/components/icon";

import { inputWithAffixClassName } from "../constants";
import { Placeholder as PlaceholderFallback } from "./components/placeholder";
import { Value as ValueFallback } from "./components/value";
import { t } from "./translations";
import { Props, Type, useDateInput } from "./use-date-input";

export interface DateInputImparativeHandle {
  closeMenu: () => void;
}

export const DateInputBase = forwardRef(function DateInputBase<T extends Type>(
  {
    props,
    input,
    children,
  }: {
    props: Props<T>;
    input: ReturnType<typeof useDateInput>;
    children: ReactNode;
  },
  ref: React.Ref<DateInputImparativeHandle | null>,
) {
  const { formatMessage } = useIntl();
  const [open, setOpen] = React.useState(false);

  const ValueComponent = props.components?.Value || ValueFallback;
  const PlaceholderComponent = props.components?.Placeholder || PlaceholderFallback;

  const closeMenu = () => {
    setOpen(false);
  };

  React.useImperativeHandle(ref, () => {
    return {
      closeMenu,
    };
  });

  return (
    <Popover.Root open={open} onOpenChange={setOpen}>
      <Popover.Trigger>
        {input.displayValue ? (
          <ValueComponent {...input}>{input.displayValue}</ValueComponent>
        ) : (
          <PlaceholderComponent {...input} />
        )}

        <div className="flex flex-shrink-0">
          {input.internalValue.length && props.clearable && !props.disabled ? (
            <button
              className={inputWithAffixClassName}
              onClick={input.onClearIconClick}
              title={formatMessage(t.clear)}
              type="button"
            >
              <CloseIcon />
            </button>
          ) : null}
        </div>
      </Popover.Trigger>
      <DropdownMenu role="listbox" className="flex overflow-auto">
        {children}
        {props.afterUiContent}
      </DropdownMenu>
    </Popover.Root>
  );
});
