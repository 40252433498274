import isEqual from "lodash.isequal";
import { FunctionComponent, HTMLAttributes, MouseEvent as ReactMouseEvent, ReactNode, useRef, useState } from "react";
import { isMatch } from "react-day-picker";

import { isMessageDescriptor, MessageDescriptor, useIntl } from "~/intl";

import { WidthIcon } from "~/shared/components/icon";

import { getToday, isPastDay, isSameDay } from "~/utils/date";

import { CommonInputProps } from "../types";
import { PlaceholderProps } from "./components/placeholder";
import { ValueProps } from "./components/value";
import { DisabledModifiers } from "./date-picker";
import { t } from "./translations";

export type Type = "date" | "daterange";
interface Time {
  hours: number;
  minutes: number;
}

export type Value<T extends Type> = T extends "daterange" ? { from: Date | ""; to: Date | "" } : Date;

export interface MenuProps {
  htmlProps: HTMLAttributes<HTMLDivElement> & { "data-testid"?: string };
  children: ReactNode;
  context: {
    inputValue: string;
    isFetchingOptions: boolean;
  };
}

export interface Components {
  Value?: FunctionComponent<ValueProps>;
  Placeholder?: FunctionComponent<PlaceholderProps>;
  Menu?: FunctionComponent<MenuProps>;
}

export interface Props<T extends Type = "date"> extends CommonInputProps<Value<T> | null> {
  type: T;
  placeholder?: MessageDescriptor | string;
  clearable?: boolean;
  disablePast?: boolean;
  disableFuture?: boolean;
  disableToday?: boolean;
  menuOffset?: number;
  components?: Components;
  afterUiContent?: ReactNode;
  disableDate?: (date: Date) => boolean;
  onMonthChange?: (date: Date) => void;
  defaultTime?: Time;
}

function useDisabledModifiers(
  disablePast: boolean,
  disableFuture: boolean,
  disableToday: boolean,
  disableDate: ((day: Date) => boolean) | undefined,
) {
  const disabled: {
    [key: string]: (day: Date) => boolean;
  } = {};

  if (disablePast) {
    disabled["disablePast"] = (day: Date) => isPastDay(day);
  }

  if (disableFuture) {
    disabled["disableFuture"] = (day: Date) => !isSameDay(getToday(), day) && !isPastDay(day);
  }

  if (disableToday) {
    disabled["disableToday"] = (day: Date) => isSameDay(getToday(), day);
  }

  if (disableDate) {
    disabled["disableDate"] = disableDate;
  }

  return disabled as DisabledModifiers;
}

export function useDateInput<T extends Type>(props: Props<T>) {
  const {
    defaultValue,
    value,
    onChange: externalOnChange,
    type,
    disablePast = false,
    disableFuture = false,
    disableToday = false,
    disableDate,
    clearable,
    placeholder: externalPlaceholder,
  } = props;

  const isControlled = !!externalOnChange;
  const { formatMessage } = useIntl();

  const valueToInternalValue = (value: Value<T> | null | undefined) => {
    const val = isControlled ? value || null : defaultValueRef.current || null;

    if (!val) {
      return [];
    } else if (isDateRange(val)) {
      if (val.from && val.to) {
        return [val.from, val.to] as Date[];
      } else {
        return [];
      }
    } else {
      return [new Date(val)] as Date[];
    }
  };

  const [internalValue, setInternalValue] = useState(() => valueToInternalValue(value));

  const [month, setMonth] = useState(() => internalValue[0] || new Date());

  const expectedInternalValue = valueToInternalValue(value);
  if (!isEqual(internalValue, expectedInternalValue)) {
    setInternalValue(expectedInternalValue);
  }

  const defaultValueRef = useRef(defaultValue);
  const inputRef = useRef<HTMLInputElement>(null);

  const disabledModifiers = useDisabledModifiers(disablePast, disableFuture, disableToday, disableDate);
  const displayValue = useDisplayValue(internalValue, type);

  const placeholder = (() => {
    if (externalPlaceholder) {
      return isMessageDescriptor(externalPlaceholder) ? formatMessage(externalPlaceholder) : externalPlaceholder;
    }
    switch (type) {
      default:
      case "date":
        return formatMessage(t.selectDate);
      case "daterange":
        return formatMessage(t.selectDateRange);
    }
  })();

  const onChange = (value: Date[]) => {
    setInternalValue(value);

    if (isControlled) {
      let nextValue: any;

      if (type === "daterange") nextValue = value[0] && value[1] ? { from: value[0], to: value[1] } : null;
      else nextValue = value[0] || null;

      externalOnChange(nextValue as Value<T> | null);
    }
  };

  const onClearIconClick = (event: ReactMouseEvent) => {
    if (clearable) {
      event.stopPropagation();
      onChange([]);
    }
  };

  return {
    inputRef,
    onChange,
    month,
    setMonth,
    disabledModifiers,
    displayValue,
    internalValue,
    onClearIconClick,
    placeholder,
  };
}

function useDisplayValue(value: Date[], type: Type) {
  const { formatDate } = useIntl();

  switch (type) {
    case "date": {
      const [date] = value;

      return date ? formatDate(date) : "";
    }

    case "daterange": {
      const [from, to] = value;

      return from && to ? (
        <>
          <span>{formatDate(from)}</span>
          <WidthIcon />
          <span>{formatDate(to)}</span>
        </>
      ) : (
        ""
      );
    }
  }
}

export function isDateDisabled(day: Date, modifiers: DisabledModifiers) {
  return isMatch(day, Object.values(modifiers));
}

export function isDateRange(value: Value<Type> | null): value is Value<"daterange"> {
  return value !== null && typeof value === "object" && "from" in value;
}
