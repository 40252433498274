import { forwardRef, useCallback, useEffect, useId, useState } from "react";
import { DayClickEventHandler } from "react-day-picker";

import { FormattedMessage, useAppLocale, useIntl } from "~/intl";

import { midday, stringToDate } from "~/utils/date";

import { TextInput } from "../text-input";
import { DateInputBase, DateInputImparativeHandle } from "./date-input-base";
import { DatePicker } from "./date-picker";
import { t } from "./translations";
import { isDateDisabled, Props, useDateInput } from "./use-date-input";

export const DateInputTypeDate = forwardRef<DateInputImparativeHandle | null, Props<"date">>((props, ref) => {
  const input = useDateInput(props);

  const { formatDate } = useIntl();
  const appLocale = useAppLocale({ userFetchEnabled: true });
  const inputId = `date-input-${useId()}`;
  const [inputValue, setInputValue] = useState("");

  const selectedDate = input.internalValue[0];

  const syncInput = useCallback(() => {
    setInputValue(selectedDate ? formatDate(selectedDate) : "");
  }, [selectedDate, formatDate]);

  useEffect(() => {
    syncInput();
  }, [syncInput]);

  const onEnter = () => {
    if (inputValue.trim() === "") {
      if (props.clearable) {
        input.onChange([]);
      }

      return;
    }

    const date = stringToDate(inputValue.trim(), appLocale);
    if (!date) return;
    if (isDateDisabled(date, input.disabledModifiers)) return;

    input.setMonth(date);
    input.onChange([date]);
  };

  const onClick: DayClickEventHandler = (day, modifiers) => {
    if (modifiers.disabled) return;

    const date = midday(day);
    if (props.clearable && input.internalValue && selectedDate?.getTime() === date?.getTime()) {
      input.onChange([]);
    } else {
      input.onChange([date]);
      input.setMonth(date);
    }
  };

  return (
    <DateInputBase props={props} input={input} ref={ref}>
      <div className="p-2">
        <div>
          <label htmlFor={inputId}>
            <FormattedMessage {...t.date} />
          </label>

          <TextInput
            ref={input.inputRef}
            id={inputId}
            type="text"
            className="w-full"
            value={inputValue}
            onChange={(value) => {
              setInputValue(value!);
            }}
            onBlur={() => {
              syncInput();
            }}
            onKeyDown={(event) => {
              if (event.key === "Enter") onEnter();
            }}
          />
        </div>
      </div>

      <div>
        <DatePicker
          onDayClick={onClick}
          month={input.month}
          onMonthChange={(date) => {
            input.setMonth(date);
            props.onMonthChange?.(date);
          }}
          selected={input.internalValue}
          disabledModifiers={input.disabledModifiers}
        />
      </div>
    </DateInputBase>
  );
});

DateInputTypeDate.displayName = "DateInputTypeDate";
