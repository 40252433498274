import { cn } from "@nephroflow/design-system/styling/utils";
import * as React from "react";
import { DayPicker } from "react-day-picker";

export type CalendarProps = React.ComponentProps<typeof DayPicker>;

function Calendar({ className, classNames, showOutsideDays = true, ...props }: CalendarProps) {
  return (
    <DayPicker
      showOutsideDays={showOutsideDays}
      className={className}
      classNames={{
        caption: "flex justify-between items-center m-2 mt-0",
        caption_label: "hidden",
        vhidden: "absolute overflow-hidden top-0 w-0 p-0 border-0 m-0 appearance-none",
        caption_dropdowns: "flex items-center gap-4",
        nav: "shrink-0",
        nav_button: cn("h-7 w-7 bg-transparent p-0 opacity-50 hover:opacity-100"),
        nav_button_previous: "absolute left-1",
        nav_button_next: "absolute right-1",
        button_reset: "hover:cursor w-10 h-10",
        head_cell: "font-normal text-sm text-secondary",
        day_selected: "text-white font-bold",
        day_outside: "text-gray-30",
        cell: "p-0",
        table: "m-2",
        day_disabled: "text-gray-20 cursor-not-allowed",
        ...classNames,
      }}
      {...props}
    />
  );
}
Calendar.displayName = "Calendar";

export { Calendar };
