import { cn } from "@nephroflow/design-system/styling/utils";
import {
  forwardRef,
  FocusEvent as ReactFocusEvent,
  KeyboardEvent as ReactKeyboardEvent,
  useEffect,
  useId,
  useState,
} from "react";
import { DayClickEventHandler } from "react-day-picker";

import { FormattedMessage, useAppLocale, useIntl } from "~/intl";

import { isDayAfter, isDayBefore, isSameDay, midday, stringToDate } from "~/utils/date";
import { omitNullable } from "~/utils/omit-nullable";

import { inputVariants } from "../constants";
import { DateInputBase, DateInputImparativeHandle } from "./date-input-base";
import { DatePicker } from "./date-picker";
import { t } from "./translations";
import { isDateDisabled, Props, useDateInput } from "./use-date-input";

type Range = [Date | null, Date | null];

export const DateInputTypeDateRange = forwardRef<DateInputImparativeHandle | null, Props<"daterange">>((props, ref) => {
  const { formatDate } = useIntl();
  const appLocale = useAppLocale({ userFetchEnabled: true });

  const [inputValueFrom, setInputValueFrom] = useState("");
  const [inputValueTo, setInputValueTo] = useState("");
  const [tempInternalValue, setTempInternalValue] = useState<Range>([null, null]);

  const input = useDateInput(props);

  const inputIdFrom = `date-input-${useId()}-from`;
  const inputIdTo = `date-input-${useId()}-to`;

  const [from, to] = input.internalValue;
  const [tempFrom, tempTo] = tempInternalValue;

  useEffect(() => {
    if (from && to) {
      setInputValueFrom(formatDate(from));
      setInputValueTo(formatDate(to));
    } else {
      setInputValueFrom(tempFrom ? formatDate(tempFrom) : "");
      setInputValueTo(tempTo ? formatDate(tempTo) : "");
    }
  }, [from, to, tempFrom, tempTo, formatDate]);

  const sortRange = (range: Range): Range => {
    const [from, to] = range;

    return from && to && isDayAfter(from, to) ? [to, from] : [from, to];
  };

  const chooseDate = (date: Date, which: "from" | "to" | "either", clearIfSameDay = false) => {
    if (!from && !to) {
      let nextValue: Range = [null, null];

      if (which === "from") {
        nextValue = sortRange([date, tempTo]);
      }

      if (which === "to") {
        nextValue = sortRange([tempFrom, date]);
      }

      if (which === "either") {
        const temp = tempFrom || tempTo;
        if (temp) {
          const isFrom = isDayBefore(date, temp);
          nextValue = isFrom ? [date, temp] : [temp, date];
        } else {
          nextValue = [date, null];
        }
      }

      {
        const [tempFrom, tempTo] = nextValue;
        if (tempFrom && tempTo) {
          input.onChange([tempFrom, tempTo]);
          setTempInternalValue([null, null]);
        } else {
          setTempInternalValue(nextValue);
        }
      }
    } else {
      if (clearIfSameDay && (isSameDay(date, from) || isSameDay(date, to))) {
        input.onChange([]);
      } else {
        if (which === "from") {
          input.onChange(sortRange([date, to]) as Date[]);
        }

        if (which === "to") {
          input.onChange(sortRange([from, date]) as Date[]);
        }

        if (which === "either") {
          const isFrom = isDayBefore(date, from);
          input.onChange(isFrom ? [date, to] : [from, date]);
        }
      }
    }
  };

  const applyInputValue = (
    event: ReactKeyboardEvent<HTMLInputElement> | ReactFocusEvent<HTMLInputElement>,
    clearIfEmpty = true,
  ) => {
    const target = event.currentTarget;
    const which = target.id === inputIdFrom ? "from" : "to";

    const resetInput = () => {
      if (which === "from") setInputValueFrom(from ? formatDate(from) : "");
      if (which === "to") setInputValueTo(to ? formatDate(to) : "");
    };

    const inputValue = target.value.trim();
    if (inputValue === "") {
      if (clearIfEmpty && props.clearable) {
        input.onChange([]);
      } else {
        resetInput();
      }

      return;
    }

    const date = stringToDate(inputValue, appLocale);
    if (!date) {
      resetInput();

      return;
    }

    if (isDateDisabled(date, input.disabledModifiers)) {
      input.setMonth(date);
      resetInput();

      return;
    }

    chooseDate(date, which);
    input.setMonth(date);
  };

  const onClick: DayClickEventHandler = (day, modifiers) => {
    if (modifiers.disabled) return;

    const date = midday(day);
    const which = "either";
    chooseDate(date, which, true);
  };

  return (
    <DateInputBase props={props} input={input} ref={ref}>
      <div className="flex gap-2 p-2">
        <div className="flex flex-1 flex-col items-start">
          <label htmlFor={inputIdFrom} className="mb-1 font-normal">
            <FormattedMessage {...t.startDate} />
          </label>

          <input
            ref={input.inputRef}
            id={inputIdFrom}
            type="text"
            size={1}
            value={inputValueFrom}
            onChange={(event) => setInputValueFrom(event.target.value!)}
            onBlur={(event) => applyInputValue(event, false)}
            className={cn(inputVariants({ size: "compact" }), "w-[136px] rounded border")}
            onKeyDown={(event) => {
              if (event.key === "Enter") applyInputValue(event);
            }}
          />
        </div>

        <div className="flex flex-1 flex-col items-start">
          <label htmlFor={inputIdTo} className="mb-1 font-normal">
            <FormattedMessage {...t.endDate} />
          </label>

          <input
            id={inputIdTo}
            type="text"
            size={1}
            className={cn(inputVariants({ size: "compact" }), "w-[136px] rounded border")}
            value={inputValueTo}
            onChange={(event) => setInputValueTo(event.target.value)}
            onBlur={(event) => applyInputValue(event, false)}
            onKeyDown={(event) => {
              if (event.key === "Enter") applyInputValue(event);
            }}
          />
        </div>
      </div>

      <div className="flex">
        <DatePicker
          onDayClick={onClick}
          month={input.month}
          onMonthChange={(date) => {
            input.setMonth(date);
            props.onMonthChange?.(date);
          }}
          asRange
          selected={omitNullable([from, to, tempFrom, tempTo])}
          disabledModifiers={input.disabledModifiers}
        />
      </div>
    </DateInputBase>
  );
});

DateInputTypeDateRange.displayName = "DateInputTypeDateRange";
