import { cn } from "@nephroflow/design-system/styling/utils";
import * as Popover from "@radix-ui/react-popover";
import { ColumnDef, ColumnFilter, ColumnMeta, Table } from "@tanstack/react-table";
import isEqual from "lodash.isequal";
import * as React from "react";

import { defineMessages, FormattedMessage, isMessageDescriptor, useIntl } from "~/intl";

import { useStableFn, useStateWithDebouncedSetter } from "~/shared/utils";

import { getDateFromDateTime } from "~/utils/time";

import { Button } from "./button";
import { Chip } from "./chip";
import {
  filterAsDateRange,
  filterAsString,
  filterAsStringArray,
  getColumnDef,
  isColumnDefFilterable,
} from "./data-table";
import { DropdownMenu } from "./dropdown-menu";
import { ArrowDownIcon, FilterIcon, ResetIcon } from "./icon";
import { DateInput } from "./inputs/date-input";
import { DateInputImparativeHandle } from "./inputs/date-input/date-input-base";
import {
  SelectInput,
  SelectInputActions,
  SelectInputImparativeHandle,
  SelectInputMenu,
  SelectInputOptions,
  SelectInputSearch,
  SelectInputTrigger,
  useSelectInputStore,
  useSelectInputStoreSelector,
} from "./inputs/select-input";
import { TextInput } from "./inputs/text-input";

const TEXT_FILTER_DEBOUNCE_TIME = 650;
const MIN_OPTIONS_FOR_SEARCH = 8;

type MetaFilter = NonNullable<ColumnMeta<unknown, unknown>["filter"]>;

type CustomProps = {
  table: Table<any>;
  onChange: (filters: ColumnFilter[]) => void;
  getInitial?: () => ColumnFilter[];
};
type HTMLProps = React.HTMLAttributes<HTMLDivElement>;
type DataTableFiltersProps = CustomProps & Omit<HTMLProps, keyof CustomProps>;

const DataTableFiltersContext = React.createContext<
  | {
      table: Table<any>;
      onChange: (filters: ColumnFilter[]) => void;
      onChangeFilter: (columnId: string, value: any) => void;
      getInitial: () => ColumnFilter[];
    }
  | undefined
>(undefined);

function DataTableFilters({
  table,
  onChange: _onChange,
  getInitial: _getInitial,
  className,
  ...props
}: DataTableFiltersProps) {
  const onChange = useStableFn(_onChange);
  const getInitial = useStableFn(_getInitial || (() => []));

  const onChangeFilter = React.useCallback(
    (columnId: string, value: any) => {
      const columns = table.getAllLeafColumns();
      const filters = table.getState().columnFilters;

      const nextFilters = filters.filter((filter) => filter.id !== columnId);
      nextFilters.push({ id: columnId, value });
      nextFilters.sort((a, b) => {
        const columnA = columns.findIndex((column) => column.id === a.id);
        const columnB = columns.findIndex((column) => column.id === b.id);
        return columnA - columnB;
      });

      onChange(nextFilters);
    },
    [table, onChange],
  );

  const columnDefs = table.getAllLeafColumns().map(getColumnDef).filter(isColumnDefFilterable);

  return (
    <DataTableFiltersContext.Provider value={{ table, onChange, onChangeFilter, getInitial }}>
      <div className={cn("flex gap-4 bg-white px-6 py-2", className)} {...props}>
        <FilterIcon className="my-[calc(1rem+1px)]" />
        <div className="flex flex-col gap-3">
          <Filters columnDefs={columnDefs} />
          <Values columnDefs={columnDefs} />
        </div>
      </div>
    </DataTableFiltersContext.Provider>
  );
}

function Filters({ columnDefs }: { columnDefs: ColumnDef<any, unknown>[] }) {
  const { onChange, getInitial } = React.useContext(DataTableFiltersContext)!;
  const { table } = React.useContext(DataTableFiltersContext)!;
  const filters = table.getState().columnFilters;
  const initial = getInitial();
  const canReset = !isEqual(filters, initial);

  return (
    <div className="flex flex-wrap gap-x-4 gap-y-1">
      {columnDefs.map((columnDef) => {
        const columnId = columnDef.id!;
        const meta = columnDef.meta || {};
        const filterRef = meta.filter;

        if (filterRef?.type === "text") {
          return <TextFilter key={columnId} columnId={columnId} />;
        }

        if (filterRef?.type === "dateRange") {
          return <DateRangeFilter key={columnId} columnId={columnId} />;
        }

        if (filterRef?.type === "select") {
          return <SelectFilter key={columnId} columnId={columnId} />;
        }

        return <React.Fragment key={columnId} />;
      })}
      {canReset ? (
        <Button impact="neutral" importance="tertiary" icon={<ResetIcon />} onClick={() => onChange(initial)}>
          <FormattedMessage {...t.resetAll} />
        </Button>
      ) : null}
    </div>
  );
}

function Values({ columnDefs }: { columnDefs: ColumnDef<any, unknown>[] }) {
  const { formatMessage } = useIntl();

  return (
    <div className="-my-1 empty:hidden" aria-label={formatMessage(t.activeFilters)}>
      {columnDefs.map((column) => {
        const columnId = column.id!;
        const meta = column.meta || {};
        const filterDef = meta.filter;

        if (filterDef?.type === "text") {
          return <TextValue key={column.id} columnId={columnId} />;
        }

        if (filterDef?.type === "select") {
          return <SelectValue key={columnId} columnId={columnId} />;
        }

        if (filterDef?.type === "dateRange") {
          return <DateRangeValue key={columnId} columnId={columnId} />;
        }

        return <React.Fragment key={columnId} />;
      })}
    </div>
  );
}

interface FilterProps {
  columnId: string;
}

type MetaFilterText = Extract<MetaFilter, { type: "text" }>;
type MetaFilterSelect = Extract<MetaFilter, { type: "select" }>;

function TextFilter({ columnId }: FilterProps) {
  const { formatMessage } = useIntl();
  const { table, onChangeFilter } = React.useContext(DataTableFiltersContext)!;
  const column = table.getColumn(columnId)!;
  const filterDef = column.columnDef.meta!.filter as MetaFilterText;

  const label = filterDef.label;
  const placeholder = filterDef.placeholder || t.search;
  const externalValue = filterAsString(column.getFilterValue()) ?? null;

  const [value, setValue] = useStateWithDebouncedSetter(
    externalValue,
    (value) => onChangeFilter(columnId, value),
    TEXT_FILTER_DEBOUNCE_TIME,
  );

  return (
    <Popover.Root>
      <Popover.Trigger asChild data-active={!!value}>
        <FilterButton>{isMessageDescriptor(label) ? formatMessage(label) : label}</FilterButton>
      </Popover.Trigger>
      <DropdownMenu>
        <TextInput
          className="border-none"
          placeholder={isMessageDescriptor(placeholder) ? formatMessage(placeholder) : placeholder}
          name={columnId}
          value={value}
          onChange={setValue}
        />
      </DropdownMenu>
    </Popover.Root>
  );
}

export function DateRangeFilter({ columnId }: FilterProps) {
  const { formatMessage } = useIntl();

  const { onChangeFilter, table, getInitial } = React.useContext(DataTableFiltersContext)!;

  const column = table.getColumn(columnId)!;
  const filterDef = column.columnDef.meta!.filter as MetaFilterSelect;
  const value = column.getFilterValue() as {
    from: Date | string;
    to: Date | string;
  };

  const label = filterDef.label;
  const handleDateRangeChange = (range: { from: Date | ""; to: Date | "" } | null) => {
    let newRange = {};
    if (range?.from) newRange = { ...newRange, from: range.from };
    if (range?.to) newRange = { ...newRange, to: range.to };

    onChangeFilter(columnId, newRange);
  };

  const initialColumnValue = getInitial().find((i) => i.id === columnId)?.value;

  const filters = table.getState().columnFilters;
  const filterColumnValue = filters.find((filter: { id: string }) => filter.id === columnId)?.value;

  const canClear = !isEqual(filterColumnValue, initialColumnValue);
  const targetRef = React.useRef<DateInputImparativeHandle | null>(null);

  const onClear = () => {
    onChangeFilter(columnId, initialColumnValue);
    targetRef.current?.closeMenu();
  };

  const from = value?.to ? new Date(value?.from) : "";
  const to = value?.to ? new Date(value?.to) : "";

  const components = React.useMemo(
    () => ({
      Value: () => <FilterButton>{isMessageDescriptor(label) ? formatMessage(label) : label}</FilterButton>,
      Placeholder: () => <FilterButton>{isMessageDescriptor(label) ? formatMessage(label) : label}</FilterButton>,
    }),
    [label, formatMessage],
  );

  return (
    <div className={cn("grid gap-2")}>
      <DateInput
        type="daterange"
        id="daterange"
        value={{
          from,
          to,
        }}
        disableFuture
        ref={targetRef}
        onChange={handleDateRangeChange}
        components={components}
        afterUiContent={canClear && filterDef.clearable !== false ? <SelectInputActions onClear={onClear} /> : null}
      />
    </div>
  );
}

function SelectFilter({ columnId }: FilterProps) {
  const { formatMessage } = useIntl();
  const { table, onChangeFilter, getInitial } = React.useContext(DataTableFiltersContext)!;
  const column = table.getColumn(columnId)!;
  const filterDef = column.columnDef.meta!.filter as MetaFilterSelect;

  const clearable = filterDef.clearable ?? true;
  const label = filterDef.label;
  const placeholder = filterDef.placeholder || t.search;
  const value = filterDef.multiple
    ? filterAsStringArray(column.getFilterValue()) || []
    : (filterAsString(column.getFilterValue()) ?? null);

  const initial = getInitial();
  const initialColumnValue = initial.find((i) => i.id === columnId)?.value;
  const filters = table.getState().columnFilters;
  const filterColumnValue = filters.find((filter: { id: string }) => filter.id === columnId)?.value;

  const canClear = !isEqual(filterColumnValue, initialColumnValue);
  const selectRef = React.useRef<SelectInputImparativeHandle>(null);

  const onClear = () => {
    onChangeFilter(columnId, initialColumnValue);
    selectRef.current?.closeMenu();
  };

  return (
    <SelectInput
      name={columnId}
      value={value}
      onChange={(value: any) => onChangeFilter(columnId, value)}
      multiple={filterDef.multiple}
      clearable={filterDef.clearable !== false}
      options={filterDef.options}
      ref={selectRef}
    >
      <SelectInputTrigger>
        <SelectInputFilterButton>{isMessageDescriptor(label) ? formatMessage(label) : label}</SelectInputFilterButton>
      </SelectInputTrigger>
      <SelectInputMenu>
        {filterDef.options.length >= MIN_OPTIONS_FOR_SEARCH ? (
          <SelectInputSearch
            placeholder={isMessageDescriptor(placeholder) ? formatMessage(placeholder) : placeholder}
          />
        ) : null}
        <SelectInputOptions />
        {canClear && clearable ? <SelectInputActions onClear={onClear} /> : null}
      </SelectInputMenu>
    </SelectInput>
  );
}

function TextValue({ columnId }: FilterProps) {
  const { formatMessage } = useIntl();
  const { table, onChangeFilter } = React.useContext(DataTableFiltersContext)!;
  const column = table.getColumn(columnId)!;
  const filterDef = column.columnDef.meta!.filter as MetaFilterText;

  const value = filterAsString(column.getFilterValue());
  const label = filterDef.label;
  if (value === undefined) return null;

  return (
    <FilterValue label={isMessageDescriptor(label) ? formatMessage(label) : label}>
      <Chip removeable onClick={() => onChangeFilter(columnId, null)} title={formatMessage(t.remove)}>
        {value}
      </Chip>
    </FilterValue>
  );
}

function DateRangeValue({ columnId }: FilterProps) {
  const { formatMessage } = useIntl();
  const { table, onChangeFilter } = React.useContext(DataTableFiltersContext)!;
  const column = table.getColumn(columnId)!;

  const value = filterAsDateRange(column.getFilterValue());

  if (value === undefined) return null;

  return (
    <>
      {value.start_time ? (
        <FilterValue label={formatMessage(t.startTime)}>
          <Chip onClick={() => onChangeFilter(columnId, null)} title={formatMessage(t.remove)}>
            {getDateFromDateTime(value.start_time?.toString())}
          </Chip>
        </FilterValue>
      ) : null}
      {value.end_time ? (
        <FilterValue label={formatMessage(t.endTime)}>
          <Chip onClick={() => onChangeFilter(columnId, null)} title={formatMessage(t.remove)}>
            {getDateFromDateTime(value.end_time?.toString())}
          </Chip>
        </FilterValue>
      ) : null}
    </>
  );
}
function SelectValue({ columnId }: FilterProps) {
  const { formatMessage } = useIntl();
  const { table, onChangeFilter } = React.useContext(DataTableFiltersContext)!;
  const column = table.getColumn(columnId)!;
  const filterDef = column.columnDef.meta!.filter as MetaFilterSelect;

  const removeable = column.columnDef.meta?.removeable ?? true;

  const label = filterDef.label;
  const value = filterDef.multiple
    ? filterAsStringArray(column.getFilterValue()) || []
    : (filterAsString(column.getFilterValue()) ?? null);
  const selection = value === null ? [] : Array.isArray(value) ? value : [value];
  const options = filterDef.options.filter((option) => selection.includes(option.value));
  const onClear = (value: string) => {
    onChangeFilter(columnId, filterDef.multiple ? selection.filter((v) => v !== value) : null);
  };
  if (!options.length) return null;

  return (
    <FilterValue label={isMessageDescriptor(label) ? formatMessage(label) : label}>
      {options.map((option, index) => (
        <Chip
          className={cn({
            "!bg-blue-20 hover:!bg-blue-30 focus-visible:border-black focus-visible:!bg-blue-30":
              filterDef.highlightOption?.(option, index) || false,
          })}
          key={index}
          removeable={removeable}
          onClick={() => (removeable ? onClear(option.value) : null)}
          title={formatMessage(t.remove)}
        >
          {option.label}
        </Chip>
      ))}
    </FilterValue>
  );
}

function FilterValue({ label, children }: { label: string; children: React.ReactNode }) {
  const { formatMessage } = useIntl();

  return (
    <div
      className="inline [&:not(:last-child)]:mr-8 [&>*:not(:last-child)]:mr-2 [&>*]:my-1"
      aria-label={formatMessage(t.activeFilter, { label })}
    >
      <span className="inline-block text-secondary" aria-hidden>
        {label}:
      </span>
      {children}
    </div>
  );
}

const FilterButton = React.forwardRef<HTMLButtonElement, React.HTMLAttributes<HTMLButtonElement>>(
  ({ children, ...props }, ref) => {
    return (
      <button
        ref={ref}
        className={cn(
          "flex items-center gap-8 rounded border border-blue-20 bg-white px-4 py-2 transition-colors",
          "hover:border-blue hover:bg-gray-00",
          "outline-none focus-visible:border-black focus-visible:bg-gray-00",
          "data-[active=true]:border-blue data-[state=open]:border-blue",
        )}
        {...props}
      >
        <span className="text-left">{children}</span>
        <ArrowDownIcon className="text-blue" />
      </button>
    );
  },
);
FilterButton.displayName = "FilterButton";

const SelectInputFilterButton = React.forwardRef<HTMLButtonElement, React.HTMLAttributes<HTMLButtonElement>>(
  ({ children, ...props }, ref) => {
    const store = useSelectInputStore();
    const selection = useSelectInputStoreSelector(store, (s) => s.selection);
    const hasSelection = selection.length > 0;

    return (
      <FilterButton ref={ref} data-active={hasSelection} {...props}>
        {children}
      </FilterButton>
    );
  },
);
SelectInputFilterButton.displayName = "SelectInputFilterButton";

const t = defineMessages({
  activeFilters: {
    id: "data_table_filters_active_filters",
    defaultMessage: "Active filters",
  },
  activeFilter: {
    id: "data_table_filters_active_filter",
    defaultMessage: "Active filter: {label}",
  },
  remove: {
    id: "data_table_filters_remove",
    defaultMessage: "Remove",
  },
  resetAll: {
    id: "data_table_filters_reset_all",
    defaultMessage: "Reset all",
  },
  search: {
    id: "data_table_filters_search",
    defaultMessage: "Search...",
  },
  startTime: {
    id: "data_table_filters_date_range_start_time",
    defaultMessage: "Start date",
  },
  endTime: {
    id: "data_table_filters_date_range_end_time",
    defaultMessage: "End date",
  },
});

export { DataTableFilters };
