import React, { forwardRef } from "react";

import { invariant } from "~/utils/invariant";

import { DateInputTypeDate } from "./data-input-type-date";
import { DateInputImparativeHandle } from "./date-input-base";
import { DateInputTypeDateRange } from "./date-input-type-daterange";
import { Props, Type } from "./use-date-input";

const DateInputInner = <T extends Type>(props: Props<T>, ref: React.Ref<DateInputImparativeHandle | null>) => {
  if (props.type === "date") {
    return <DateInputTypeDate {...(props as Props<"date">)} ref={ref} />;
  }
  if (props.type === "daterange") {
    return <DateInputTypeDateRange {...(props as Props<"daterange">)} ref={ref} />;
  }

  invariant(false, "Invalid date input type");
};

export const DateInput = forwardRef(DateInputInner) as <T extends Type>(
  props: Props<T> & { ref?: React.Ref<DateInputImparativeHandle | null> },
) => ReturnType<typeof DateInputInner>;
