import { defineMessages, useTranslatedResource } from "~/intl";

/*=================Months==================*/
type Month =
  | "january"
  | "february"
  | "march"
  | "april"
  | "may"
  | "june"
  | "july"
  | "august"
  | "september"
  | "october"
  | "november"
  | "december";

export function useLongMonths() {
  const { formatValue, options } = useTranslatedResource(longMonth);

  return {
    monthOptions: options,
    formatMonth: formatValue,
    formatMonthNumber: (month: number) => formatValue(Object.keys(longMonth)[month] as any),
  };
}

export function useShortMonths() {
  const { formatValue, options } = useTranslatedResource(shortMonth);

  return {
    monthOptions: options,
    formatMonth: formatValue,
    formatMonthNumber: (month: number) => formatValue(Object.keys(shortMonth)[month] as any),
  };
}

const longMonth = defineMessages<Month>({
  january: {
    id: "date_month_long_january",
    defaultMessage: "January",
  },
  february: {
    id: "date_month_long_february",
    defaultMessage: "February",
  },
  march: {
    id: "date_month_long_march",
    defaultMessage: "March",
  },
  april: {
    id: "date_month_long_april",
    defaultMessage: "April",
  },
  may: {
    id: "date_month_long_may",
    defaultMessage: "May",
  },
  june: {
    id: "date_month_long_june",
    defaultMessage: "June",
  },
  july: {
    id: "date_month_long_july",
    defaultMessage: "July",
  },
  august: {
    id: "date_month_long_august",
    defaultMessage: "August",
  },
  september: {
    id: "date_month_long_september",
    defaultMessage: "September",
  },
  october: {
    id: "date_month_long_october",
    defaultMessage: "October",
  },
  november: {
    id: "date_month_long_november",
    defaultMessage: "November",
  },
  december: {
    id: "date_month_long_december",
    defaultMessage: "December",
  },
});

const shortMonth = defineMessages<Month>({
  january: {
    id: "date_month_short_january",
    defaultMessage: "Jan",
  },
  february: {
    id: "date_month_short_february",
    defaultMessage: "Feb",
  },
  march: {
    id: "date_month_short_march",
    defaultMessage: "Mar",
  },
  april: {
    id: "date_month_short_april",
    defaultMessage: "Apr",
  },
  may: {
    id: "date_month_short_may",
    defaultMessage: "May",
  },
  june: {
    id: "date_month_short_june",
    defaultMessage: "Jun",
  },
  july: {
    id: "date_month_short_july",
    defaultMessage: "Jul",
  },
  august: {
    id: "date_month_short_august",
    defaultMessage: "Aug",
  },
  september: {
    id: "date_month_short_september",
    defaultMessage: "Sep",
  },
  october: {
    id: "date_month_short_october",
    defaultMessage: "Oct",
  },
  november: {
    id: "date_month_short_november",
    defaultMessage: "Nov",
  },
  december: {
    id: "date_month_short_december",
    defaultMessage: "Dec",
  },
});

/*==============Week Days================*/
type Day = "sunday" | "monday" | "tuesday" | "wednesday" | "thursday" | "friday" | "saturday";

export function useLongWeekdays() {
  const { formatValue, options } = useTranslatedResource(longWeekDays);

  return {
    weekdayOptions: options,
    formatDay: formatValue,
    formatDayNumber: (day: number) => formatValue(Object.keys(longWeekDays)[day] as Day),
  };
}

export function useShortWeekdays() {
  const { formatValue, options } = useTranslatedResource(shortWeekDays);

  return {
    weekdayOptions: options,
    formatDay: formatValue,
    formatDayNumber: (day: number) => formatValue(Object.keys(shortWeekDays)[day] as Day),
  };
}

const longWeekDays = defineMessages<Day>({
  sunday: { id: "date_weekday_long_sunday", defaultMessage: "Sunday" },
  monday: { id: "date_weekday_long_monday", defaultMessage: "Monday" },
  tuesday: { id: "date_weekday_long_tuesday", defaultMessage: "Tuesday" },
  wednesday: { id: "date_weekday_long_wednesday", defaultMessage: "Wednesday" },
  thursday: { id: "date_weekday_long_thursday", defaultMessage: "Thursday" },
  friday: { id: "date_weekday_long_friday", defaultMessage: "Friday" },
  saturday: { id: "date_weekday_long_saturday", defaultMessage: "Saturday" },
});

const shortWeekDays = defineMessages<Day>({
  sunday: { id: "date_weekday_short_sun", defaultMessage: "Sun" },
  monday: { id: "date_weekday_short_mon", defaultMessage: "Mon" },
  tuesday: { id: "date_weekday_short_tue", defaultMessage: "Tue" },
  wednesday: { id: "date_weekday_short_wed", defaultMessage: "Wed" },
  thursday: { id: "date_weekday_short_thu", defaultMessage: "Thu" },
  friday: { id: "date_weekday_short_fri", defaultMessage: "Fri" },
  saturday: { id: "date_weekday_short_sat", defaultMessage: "Sat" },
});

/*=============Common Translations============*/

export const t = defineMessages({
  selectDate: {
    id: "date_input_select_date",
    defaultMessage: "Select date...",
  },
  selectDateRange: {
    id: "date_input_select_date_range",
    defaultMessage: "Select date range...",
  },
  openMenu: {
    id: "date_input_open_menu",
    defaultMessage: "Open menu",
  },
  closeMenu: {
    id: "date_input_close_menu",
    defaultMessage: "Close menu",
  },
  clear: {
    id: "date_input_clear",
    defaultMessage: "Clear",
  },
  dates: {
    id: "date_input_dates",
    defaultMessage: "Dates",
  },
  date: {
    id: "date_input_date",
    defaultMessage: "Date",
  },
  startDate: {
    id: "date_input_start_date",
    defaultMessage: "Start date",
  },
  endDate: {
    id: "date_input_end_date",
    defaultMessage: "End date",
  },
  pointInTime: {
    id: "date_input_point_in_time",
    defaultMessage: "Point in time",
  },
});
